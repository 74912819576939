import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AggregateData } from '../../models/AggregateData';

export function tempFetchCount() {
    return new Promise<{ data: AggregateData }>((resolve) =>
        setTimeout(() => {
            resolve({
                data: {
                    adSpend: 1925.16,
                    users: 903,
                    revenue: 9750.97,
                },
            });
        }, 500)
    );
}

export const getAggregateDataAsync = createAsyncThunk(
    'dashboard/getAggregateData',
    async () => {
        const response = await tempFetchCount();
        // The value we return becomes the `fulfilled` action payload
        return response.data;
    }
);
